import { HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_DELEGATE_CLICK } from ".";
import { OPT_IN_CONTENT_BLOCKER_ALL } from "..";
import { dispatchResizeEvent } from "../utils";
/**
 * Listen to clicked content blockers and automatically delegate the click
 * event to the underlying element. This is especially useful for overlays of videos
 * so the user does not need to click twice.
 */
function delegateClick(element, _ref) {
  let {
    same,
    nextSibling,
    parentNextSibling
  } = _ref;
  // Save found result
  let delegateClick;
  let hide;
  const delegateClickAttribute = element.getAttribute(HTML_ATTRIBUTE_DELEGATE_CLICK);
  const nextElementSibling = element.nextElementSibling;
  const parentElement = element.parentElement;
  const parentElementNextSibling = parentElement?.nextElementSibling;
  loop1: for (const [node, delegateClickElements] of [[
  // Same element (= the blocked element)
  element, [...(same || []), ...(delegateClickAttribute ? [JSON.parse(delegateClickAttribute)] : [])]], [
  // Next sibling element to the blocked content
  nextElementSibling, nextSibling], [
  // Next sibling element of parent of the blocked content
  parentElementNextSibling, parentNextSibling]]) {
    if (node && delegateClickElements) {
      for (const delegateClickElement of delegateClickElements) {
        const selector = typeof delegateClickElement === "string" ? delegateClickElement : delegateClickElement.selector;
        if (typeof delegateClickElement !== "string") {
          hide = delegateClickElement.hide || false;
        }
        if (
        // Special case: force self element to be delegated (useful in conjunction with `delegateClick()` selector syntax function)
        selector === "self" ||
        // Usual selector match
        node.matches(selector)) {
          delegateClick = node;
          break loop1;
        }

        // Check for children selector
        const childrenSelected = node.querySelector(selector);
        if (childrenSelected) {
          delegateClick = childrenSelected;
          break loop1;
        }
      }
    }
  }

  // We delegate the click to an element, let's check if it is also blocked and listen to unblock
  if (delegateClick) {
    const fn = () => setTimeout(() => {
      delegateClick.click();
      if (hide) {
        delegateClick.style.setProperty("display", "none", "important");
      }
      dispatchResizeEvent(element);
    }, /* Let's delay so we can start after `manipulateDom#customTriggers()` */100);
    if (delegateClick.hasAttribute(HTML_ATTRIBUTE_COOKIE_IDS)) {
      delegateClick.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, fn, {
        once: true
      });
    } else {
      fn();
    }
  }
  return delegateClick;
}
export { delegateClick };