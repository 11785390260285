import { idx } from "@devowl-wp/cookie-consent-web-client";
import { OPT_IN_CONTENT_BLOCKER_ALL, OPT_IN_CONTENT_BLOCKER, HTML_ATTRIBUTE_COOKIE_IDS } from "@devowl-wp/headless-content-unblocker";
import { getOtherOptionsFromWindow } from "../../utils";
const MEMORIZE_ELEMENTOR_HANDLER_PROPERTY = "rcb-overwritten";
function overwrite(prototype, _ref) {
  let {
    delay,
    optIn,
    optInAll
  } = _ref;
  const {
    onInit,
    [MEMORIZE_ELEMENTOR_HANDLER_PROPERTY]: already
  } = prototype;
  if (already) {
    return;
  }
  prototype[MEMORIZE_ELEMENTOR_HANDLER_PROPERTY] = true;

  // `onInit` is called for each element, even it is blocked
  prototype.onInit = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    const $element = this.$element;
    const element = $element.get(0);
    if ($element.attr(HTML_ATTRIBUTE_COOKIE_IDS)) {
      $element.attr(MEMORIZE_ELEMENTOR_HANDLER_PROPERTY, "1");
      element.addEventListener(OPT_IN_CONTENT_BLOCKER, _ref2 => {
        let {
          detail
        } = _ref2;
        optIn?.($element, detail, this);
      });
      element.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, _ref3 => {
        let {
          detail
        } = _ref3;
        optInAll?.($element, detail, this);
        setTimeout(() => onInit.apply(this, args), delay || 0);
      });
    } else {
      return onInit.apply(this, args);
    }
  };
}

/**
 * Overwrite Elementor modules and wait until consent is given for blocked module.
 * Passed `handlers` can be widget names like `video.default` or class names `VideoPlaylistHandler`
 * for dynamic modules.
 */
function hijackElementorOnInit(handlers) {
  const {
    wrapFn
  } = window.consentApi;
  wrapFn({
    // See https://github.com/elementor/elementor/blob/2ee675caf03908f8c66e16298e923a24b206df54/assets/dev/js/frontend/frontend.js#L319-L322
    object: () => idx(window, window => window.elementorFrontend),
    key: "initModules"
  }, _ref4 => {
    let {
      objectResolved
    } = _ref4;
    wrapFn({
      object: objectResolved.elementsHandler,
      key: "addHandler"
    }, _ref5 => {
      let {
        args: [handlerClass]
      } = _ref5;
      for (const handler of handlers) {
        if (handlerClass.name === handler.className) {
          overwrite(handlerClass.prototype, handler);
        }
      }
      return true;
    });

    // [Plugin Comp] https://github.com/kobizz/dialogs-manager (used by Elementor)
    // When using a Elementor Popup which also can be closed through a "outside click",
    // it will also be closed when the cookie banner is visible and clicking on "Accept all".
    // We need to prevent this by ignoring our cookie banner in the "outside click" handler.
    wrapFn({
      object: objectResolved,
      key: "getDialogsManager"
    }, _ref6 => {
      let {
        callOriginal
      } = _ref6;
      const dialogInstance = callOriginal();

      // https://github.com/kobizz/dialogs-manager/blob/01067d16ae3db87d4e86ab3b84b1248122819725/src/js/dialogs-manager.js#L85-L95
      wrapFn({
        object: dialogInstance,
        key: "createWidget"
      }, _ref7 => {
        let {
          original,
          args: [widgetType, properties = {}],
          that
        } = _ref7;
        // Add ignore statement: https://github.com/kobizz/dialogs-manager/blob/01067d16ae3db87d4e86ab3b84b1248122819725/src/js/dialogs-manager.js#L255-L261
        const ignore = `#${getOtherOptionsFromWindow().pageRequestUuid4},.rcb-db-container,.rcb-db-overlay`;
        properties.hide = properties.hide || {};
        const {
          hide
        } = properties;
        hide.ignore = hide.ignore || "";
        hide.ignore = [...hide.ignore.split(","), ignore].join(",");
        const widgetInstance = original.apply(that, [widgetType, properties]);
        return widgetInstance;
      });
      return dialogInstance;
    });
    return true;
  });
}
export { hijackElementorOnInit, MEMORIZE_ELEMENTOR_HANDLER_PROPERTY };